import CustomModal from 'shared/components/customModal/CustomModal'
import { SwapWidget, Theme } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'
import { useWeb3React } from '@web3-react/core'
import { useState } from 'react'
import { walletICON, address_copy_icon } from 'shared/icons'
import NextImage from 'shared/components/nextImage/NextImage'
import { copyToClipboard } from 'utilities/helpers'

import {
  Wrapper,
  TabsWrapper,
  TabButton,
  PaymentAddress,
  PaymentAddressText,
  PaymentBodyLeft,
  PaymentDescriptionOne,
  PaymentDescriptionTwo,
  PaymentWalletIcon,
} from './SwapAndFundModal.style'
import { themeDark } from 'styles/theme'
import { useAppSelector } from 'hooks/useAppSelector'

interface IProps {
  headerText?: string
  show: boolean
  toggleModal: (value: boolean) => void
  tab?: TTab
  showAddFund?: boolean
}

const theme: Theme = {
  borderRadius: 10,
  accent: themeDark.brandColor
}

type TTab = 'depositCrypto' | 'buyWithCard'

const buttons: { id: TTab; label: string }[] = [
  {
    id: 'depositCrypto',
    label: 'Deposit Crypto',
  },
  {
    id: 'buyWithCard',
    label: 'Buy with card',
  },
]

const AddFund = ({ walletAddress }: { walletAddress?: string | null }) => {
  const { theme } = useAppSelector(state => state.theme)
  return (
    <PaymentBodyLeft>
      <PaymentWalletIcon>
        <NextImage src={walletICON} alt="wallet" />
      </PaymentWalletIcon>
      <PaymentDescriptionOne>
        Transfer funds from an exchange (i) or another wallet to your wallet address below:
      </PaymentDescriptionOne>
      <PaymentAddress themeMode={theme}>
        <PaymentAddressText>{walletAddress}</PaymentAddressText>
        <NextImage
          onClick={async () => await copyToClipboard(walletAddress || '')}
          src={address_copy_icon}
          alt="wallet"
        />
      </PaymentAddress>
      <PaymentDescriptionTwo>
        Only send ETH or any other ERC-20 token to this address
      </PaymentDescriptionTwo>
    </PaymentBodyLeft>
  )
}

export const SwapAndFundModal = ({
  headerText,
  show,
  toggleModal,
  tab: givenTab,
  showAddFund,
}: IProps) => {
  const { library, account } = useWeb3React()
  const [tab, setTab] = useState<TTab>(givenTab || 'depositCrypto')

  return (
    <CustomModal headerText={headerText || 'Add Funds'} show={show} toggleModal={toggleModal}>
      <Wrapper>
        <TabsWrapper>
          {buttons.map(({ id, label }) => (
            <TabButton
              active={id === tab}
              key={id}
              onClick={() => {
                setTab(id)
              }}
            >
              {label}
            </TabButton>
          ))}
        </TabsWrapper>
        {tab === 'depositCrypto' &&
          (showAddFund ? (
            <AddFund walletAddress={account} />
          ) : (
            <div style={{ padding: "20px" }}>
              <SwapWidget provider={library.givenProvider} width="100%" theme={theme} />
            </div>
          ))}
        {tab === 'buyWithCard' && (
          <div style={{ height: '70vh' }}>
            <iframe
              allow="accelerometer; autoplay; camera; gyroscope; payment"
              frameBorder="0"
              height="100%"
              src={`https://buy-staging.moonpay.io?apiKey=${process.env.MOONPAY_PUBLISHABLE_KEY}`}
              width="100%"
            />
          </div>
        )}
      </Wrapper>
    </CustomModal>
  )
}

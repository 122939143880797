import { NFTCardThreeDotImage } from 'shared/components/nftCard/NFTCard.styled'
import styled, { css, keyframes } from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
import { media } from 'utilities/helpers'

const animateKeyframe = keyframes`
0% {transform : translateX(300px)}
100% {transform-x :translateX(0px)}
`

export const DropdownBody = styled.div`
  position: fixed;
  z-index: 1000;
  top: 88px;
  width: 100%;
  left: 0;
  height: calc(100vh - 88px);
  background: rgb(0 0 0 / 70%);
`

export const DropdownContainer = styled.div<any>`
  width: 490px;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
  position: absolute;
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
  z-index: 1000;
  right: 0;
  top: 0;
  ${({ dropdown }) =>
    dropdown === true &&
    css`
      animation: ${animateKeyframe} linear 0.5s;
    `}
  overflow: auto;
  height: 100%;
  ${media.sm`
  top:0px;
  `}
  ${media.md`
   width:100%;
  `}
  & .balance-card {
    margin: 10px 20px;
  }

  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .ml-8 {
    margin-left: 0.5rem;
  }

  .p-16 {
    padding: 1rem;
  }
`

export const DropdownHeader = styled.div<any>`
  border-bottom: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeDark.textSecondary : '#353540')};
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .wallet-address {
    font-size: 14px;
    color: ${({ theme }) => theme.textTertiary};
    margin-right: 8px;
  }

  & .copy-btn {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }

  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    margin: 0 5px;
  }
  ${media.s`
  flex-direction:column
  `}
`

export const LoginWalletProvider = styled.div<any>`
  width: 100%;
  height: 80px;
  background: ${({ themeMode }) => (themeMode === 'light' ? 'transparent' : '#0d0d0d')};
  border: ${({ themeMode }) =>
    themeMode === 'light' ? `2px solid ${themeDark.brandColor}` : '1px solid #322323'};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin: auto auto 20px auto;
  cursor: pointer;
  position: relative;
  transition: all linear 0.2s;
  :hover {
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeLight.inputHover : themeDark.assetDetail.backArrowBg};
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light' ? 'none' : '0px 0px 10px rgba(0, 0, 0, 0.25)'};
  }
`
export const LoginWalletProviderName = styled.h4<any>`
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.909091px;
  margin-left: 15px;
  flex: 1;
`
export const BodyWrapper = styled.div`
  padding: 10px 20px;
`
export const LoginWalletProviderDescription = styled.p<any>`
  font-weight: 500;
  font-size: 14px;
  float: right;
  letter-spacing: 0.909091px;
  color: ${themeDark.assetDetail.cardTitle};
`
export const InfoText = styled.p<any>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.909091px;
  color: ${({ themeMode }) => (themeMode === 'light' ? ' #554E57' : themeDark.white)};
  margin: 20px;
  span {
    color: ${themeDark.brandColor};
    cursor: pointer;
    margin: 0 5px;
  }
`
export const LoginWallets = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 20px 0 20px 0;
  height: ${({ showMoreOptions }) => (showMoreOptions ? 'auto' : '190px')};
  overflow: hidden;
`

export const ShowMoreButton = styled.div<any>`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BalanceCardWrapper = styled.div<any>`
  background: ${({ themeMode }) =>
    themeMode === 'light' ? '#EAF7F3' : themeDark.assetDetail.backArrowBg};
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
  border-radius: 10px;
  margin: 20px 0;
`

export const TotalBalance = styled.div<any>`
  & .overflowBal {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .item {
    margin-bottom: 16px;
    max-width: 100%;
  }

  & .balance {
    color: ${({ theme }) => theme.textTertiary};
  }
  h3 {
    font-size: 30px;
    color: ${({ theme }) => theme.text};
  }
`

export const TokenBalancesWrapper = styled.div<any>`
  & .overflowBal {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const TokenBalance = styled.div<any>`
  background: ${({ theme }) => theme.bgTertiary};
  margin: 5px 0;
  border-radius: 10px;
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
`
export const H5 = styled.h5`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
  word-break: break-all;
  text-align: right;
`

export const P = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.textTertiary};
`

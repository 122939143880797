import axios from 'axios'
// import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'
import { getCSRFToken } from 'logic/reactQuery/sharedService'
import toast from 'react-hot-toast'

const baseURL = process.env.API_BASE_URL

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL,
})
export const axiosInstanceNew = axios.create({
  baseURL,
})
let redirect: any
if (typeof window !== 'undefined') {
  redirect = window.location.pathname
}

// const useRedirect = () => {
//   const router = useRouter()
//   if (redirect) {
//     router.push(redirect)
//   }
// }

axiosInstance.interceptors.request.use(
  async (req: any) => {
    const accessToken: any = window.localStorage.getItem('accessToken')
    const expirationDate: any = window.localStorage.getItem('tokenExpiry')
    let authToken: any = typeof window !== 'undefined' ? JSON.parse(accessToken) : null
    if (!authToken) {
      return req
    } else if (authToken) {
      // const user: any = jwt_decode(authToken)
      // const isExpired = dayjs.unix(user?.exp).diff(dayjs()) < 1
      // let isExpired = (new Date().getTime()) < expirationDate
      const isExpired = dayjs.unix(expirationDate).diff(dayjs()) < 1
      if (isExpired) {
        toast.success('Session Expired, Please Login Again')
        if (typeof window !== 'undefined') {
          const isExistingUser = window.localStorage.getItem('userInfo')
          const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
          const userAuthInfoUpdated = userAuthInfo.filter(
            (user: any) => user.accessToken !== authToken,
          )
          if (userAuthInfoUpdated) {
            const newUserInfo = [...userAuthInfoUpdated]
            typeof window !== 'undefined' &&
              window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
          }
          window.localStorage.removeItem('accessToken')
          window.localStorage.removeItem('tokenExpiry')
          window.localStorage.removeItem('provider')
          window.location.href = '/login'
        }

        return req
      } else {
        if (
          (req.method === 'post' || req.method === 'patch' || req.method === 'put') &&
          req.url !== '/user' &&
          req.url !== '/user/logout' &&
          req.url !== '/admin/executeMetaTransaction'
        ) {
          const response = await getCSRFToken()
          req.headers['XSRF-TOKEN'] = `${response.data.csrfToken}`
          req.headers.Authorization = `Bearer ${authToken}`
          return req
        } else if (req.method === 'patch' && req.url === '/user') {
          const response = await getCSRFToken()
          req.headers['XSRF-TOKEN'] = `${response.data.csrfToken}`
          req.headers.Authorization = `Bearer ${authToken}`
          return req
        } else if (req.method === 'post' && req.url === '/admin/executeMetaTransaction') {
          const response = await getCSRFToken()
          req.headers['XSRF-TOKEN'] = `${response.data.csrfToken}`
          return req
        } else {
          req.headers.Authorization = `Bearer ${authToken}`
          return req
        }
      }
    }
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  res => {
    return res?.data
  },
  error => {
    console.log('myError', error)
    // return Promise.reject(error)
    if (error?.message === 'Request failed with status code 401') {
      console.log('res2')
      let accessToken: any
      if (typeof window !== 'undefined' && accessToken) {
        accessToken = window.localStorage.getItem('accessToken')
        let authToken: any = JSON.parse(accessToken)
        const isExistingUser = window.localStorage.getItem('userInfo')
        const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
        const userAuthInfoUpdated = userAuthInfo.filter(
          (user: any) => user.accessToken !== authToken,
        )
        if (userAuthInfoUpdated) {
          const newUserInfo = [...userAuthInfoUpdated]
          typeof window !== 'undefined' &&
            window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
        }
      }
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('tokenExpiry')
      window.localStorage.removeItem('provider')
      window.location.href = '/login'
    }
  },
)

export const apiRequest = ({ ...options }) => {
  // client.defaults.headers.common.Authorization = `Bearer ${token}`;
  // client.defaults.headers.common["Content-type"] = "application/json";
  // const onSuccess = (response: any) => response
  // const onError = (error: any) => error
  return axiosInstance(options)
}

// ! ALL THE API ROUTES STARTING WITH /api/v1/user

import { useQuery, useMutation, useQueryClient } from 'react-query'
import toast from 'react-hot-toast'
import { CacheKeys } from './cacheKeys'
import { axiosInstance } from 'utilities/axiosInterceptor'
import { apiEndPoints } from 'utilities/apiUrls'
import { IUser, I_ProfileCount } from 'shared/interface'
import axios from 'axios'
import { generateQueryString } from 'utilities/helpers'

export interface I_LoginOptions {
  walletAddress: string | undefined | null
  signature: string
  signature_message: string
}

interface ILoginResponse extends IUser {
  access_token: string
}

const loginAuthenticationFn = (options: I_LoginOptions) => {
  const url = apiEndPoints.user.root
  return axiosInstance.post<{ message: ILoginResponse }>(url, options)
}

export const useLoginMutation = () => {
  return useMutation(loginAuthenticationFn)
}

const getUserDetails = (walletAddress?: string | null | undefined) => {
  const url = `${apiEndPoints.user.getUserDetailsByWalletaddress}/${walletAddress}`
  return axiosInstance.get<{ message: IUser }>(url)
}
const getUserCollections = (id?: string | null | undefined) => {
  const url = `${apiEndPoints.user.getUserCollections}/${id}`
  return axiosInstance.get(url)
}
export const useGetUserDetailsQuery = (walletAddress: string | null | undefined) => {
  return useQuery([CacheKeys.user, walletAddress], () => getUserDetails(walletAddress), {
    enabled: !!walletAddress,
    refetchOnWindowFocus: false,
  })
}
export const useGetUserCollectionsQuery = (id?: string | null | undefined) => {
  return useQuery([CacheKeys.userCollections], () => getUserCollections(id))
}
interface IProfileUpdateOptions {
  firstName: string
  lastName: string
  userName: string
  email: string
  imageUrl: string
  bannerUrl: string
  bio: string
  twitterHandle: string
  instagramHandle: string
  website: string
}
const profileUpdateFn = (options: IProfileUpdateOptions) => {
  const url = apiEndPoints.user.root
  return axiosInstance.patch<{ message: IUser }>(url, options)
}

export const useProfileUpdateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(profileUpdateFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(CacheKeys.user)
      toast('Profile successfully updated')
    },
    // onError: (error: any) => {
    //   toast('Profile update failed')
    // },
  })
}

export interface IGetPresignedURLOptions {
  fileName: string
  fileType: string
  filePath: string
}

const getPresignedURLFn = (options: IGetPresignedURLOptions) => {
  const url = apiEndPoints.user.getPresignedURL
  return axiosInstance.post<string>(url, options)
}

export const useGetPresignedURLMutation = () => {
  return useMutation(getPresignedURLFn)
}

const getProfileCountStats = (walletAddress?: string | null) => {
  const url = `${apiEndPoints.user.getProfileCount}/${walletAddress}`
  return axiosInstance.get<{ message: I_ProfileCount }>(url)
}

export const useGetProfileCountStatsQuery = (walletAddress?: string | null) => {
  return useQuery([CacheKeys.nftItemCount, walletAddress], () =>
    getProfileCountStats(walletAddress),
  )
}

// Subscribe to News Letter
interface I_NewsLetterOptions {
  userEmail: string
}
interface I_CollectionMinPriceOffer {
  collectionsMinimumOfferPrice: any
}

const collectionMinPriceOffer = (options: I_CollectionMinPriceOffer) => {
  const url = apiEndPoints.user.collectionMinPrice
  return axiosInstance.put(url, options)
}
const subscribeMutationFn = (options: I_NewsLetterOptions) => {
  const url = apiEndPoints.user.subscribeNewsLetter
  return axiosInstance.post(url, options)
}

export const useSubscribeMutation = () => {
  return useMutation(subscribeMutationFn)
}
export const useCollectionMinPriceOffer = () => {
  return useMutation(collectionMinPriceOffer, {
    onSuccess: () => {
      toast('Collection Minimum Offer Price successfully updated')
    },
  })
}
// make nft as profile page
const nftProfilePage = (nftId: string) => {
  const url = `${apiEndPoints.user.nftProfilePic}?nftItemId=${nftId}`
  return axiosInstance.patch(url, nftId)
}
export const useNftProfilePage = () => {
  const queryClient = useQueryClient()
  return useMutation(nftProfilePage, {
    onSuccess: () => {
      toast('Profile picture updated')
      queryClient.invalidateQueries(CacheKeys.user)
    },
  })
}

// for email EmailVerification

export interface I_emailVerifyFn {
  params: string
  config: any
}
const getverifyEmail = async (data: I_emailVerifyFn) => {
  const baseURL = process.env.API_BASE_URL
  const url = `${baseURL}${apiEndPoints.user.verifyEmail}/${data?.params}`
  return await axios.get(url, data?.config)
}

export const useGetVerifyEmail = (data: I_emailVerifyFn) => {
  return useQuery([CacheKeys.verifyEmail, data], () => getverifyEmail(data), {
    enabled: !!data?.params,
  })
}

const getresendEmail = () => {
  const url = `${apiEndPoints.user.resendEmail}`
  return axiosInstance.get(url)
}

export const useGetResendEmail = () => {
  return useQuery([CacheKeys.resendEmail], () => getresendEmail(), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}

export interface KYC_Options {
  request_data: string
  document_image_page0: string
  document_image_page1: string
  photo_for_face_comparison: string
  supplementary_document0_page0: string
}

/**This function is to submit the kyc details to backend */
const kycCreateSessionFn = (options: any) => {
  const url = `${apiEndPoints.user.kycCreateSession}`
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  return axiosInstance.post(url, options, config)
}

export const useKycSubmitMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(kycCreateSessionFn, {
    onSuccess: () => {
      toast('Kyc details updated')
      queryClient.invalidateQueries(CacheKeys.user)
    },
  })
}

/**This function is to check the status of kyc details after submitting */
const kycStatusFn = ({ queryKey }: any) => {
  const requestId = queryKey[1]
  const url = `${apiEndPoints.user.kycStatus}`
  return axiosInstance.get(url)
}

export const useKycStatusQuery = (request_id: string, stopInterval: string) => {
  return useQuery([CacheKeys.getKycStatus, request_id], kycStatusFn, {
    refetchInterval: 10000,
    enabled: request_id && (stopInterval === '' || stopInterval === undefined) ? true : false,
  })
}

const kycUpdateSessionFn = (kycId: string) => {
  const url = `${apiEndPoints.user.kycUpdateSession}/${kycId}`
  return axiosInstance.patch(url)
}

export const useKycUpdateSessionQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(kycUpdateSessionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(CacheKeys.kycUpdateSession)
      toast('kyc session updated')
    },
  })
}

const syncProfileFn = () => {
  const url = `${apiEndPoints.user.syncProfile}`
  return axiosInstance.get(url)
}

export const useSyncProfileQuery = () => {
  return useQuery([CacheKeys.syncProfile], syncProfileFn)
}

const userLogoutFn = () => {
  const url = `${apiEndPoints.user.logout}`
  return axiosInstance.post(url)
}

export const useUserLogoutQuery = () => {
  return useMutation(userLogoutFn)
}

//for checking validity of userName and Email
// export interface validUser {
//   userName?: string
//   email?: string
// }

const checkValidUser = (userInfo?: any) => {
  return axiosInstance.get(`${apiEndPoints.user.uniqueUser}?userName=${userInfo}`)
}

export const useCheckValidUserQuery = (userInfo?: any) => {
  return useQuery([CacheKeys.validUser, userInfo], () => checkValidUser(userInfo), {
    enabled: !!userInfo,
    refetchOnWindowFocus: false,
  })
}
const checkValidEmail = (userInfo?: any) => {
  return axiosInstance.get(`${apiEndPoints.user.uniqueUser}?email=${userInfo}`)
}

export const useCheckValidEmailQuery = (userInfo?: any) => {
  return useQuery([CacheKeys.validUser, userInfo], () => checkValidEmail(userInfo), {
    enabled: !!userInfo,
    refetchOnWindowFocus: false,
  })
}

//checkIsUserBlocked

const getIsUserBlocked = async ({ queryKey }: any) => {
  const walletAddress = queryKey[1]
  const url = `${apiEndPoints.user.checkIsUserBlocked}/${walletAddress}`
  return await axiosInstance.get(url)
}

export const useCheckIsUserBlocked = (walletAddress: any) => {
  return useQuery([CacheKeys.isBlocked, walletAddress], getIsUserBlocked, {
    enabled: !!walletAddress,
  })
}

//cashback integration

const getCashbackForUser = () => {
  const url = `${apiEndPoints.user.cashbackForUser}`
  return axiosInstance.get(url)
}

export const useCashbackForUser = () => {
  return useQuery([CacheKeys.cashbackForUser], getCashbackForUser)
}

const getCashbackAmountForUser = () => {
  const url = `${apiEndPoints.user.cashbackAmountForUser}`
  return axiosInstance.get(url)
}

export const useCashbackAmountForUser = () => {
  return useQuery([CacheKeys.cashbackAmountForUser], getCashbackAmountForUser)
}

const getUserWalletBalance = () => {
  const url = `${apiEndPoints.user.getUserWalletBalance}`
  return axiosInstance.get(url)
}

export const useGetUserWalletBalance = (walletAddress: any) => {
  return useQuery([CacheKeys.getUserWalletBalance], getUserWalletBalance, {
    enabled: !!walletAddress,
  })
}

//details for multiple owners

const userDetailsFn = (options: any) => {
  const url = apiEndPoints.user.userDetails
  return axiosInstance.put(url, options)
}

export const userDetailsMutation = () => {
  return useMutation(userDetailsFn, {
    onSuccess: (data: any) => {
      return data
    },
  })
}

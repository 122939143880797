// export const merkleValidatorAddress_ETHEREUM = '0x1717a1C918fBd80b09a5653132C9eb8a8C362C7a'
// export const WyvernAtomicizer_ETHEREUM = '0xceF151B39916D0872880247D09937fe7807F217F'
// export const merkleValidatorAddress_POLYGON = '0x1717a1C918fBd80b09a5653132C9eb8a8C362C7a'
// export const WyvernAtomicizer_POLYGON = '0xceF151B39916D0872880247D09937fe7807F217F'

// export const ownerAddress = '0x287A135702555F69BA6eE961f69ee60Fbb87A0e8'
export const merkleValidatorAddress_ETHEREUM = '0xE3dF7076A78c0144B9E53dF4FD591cBce5f1f187'
export const WyvernAtomicizer_ETHEREUM = '0xd3B1a102a4CDF53eEa55dfEcf62C3b78020F625A'

export const merkleValidatorAddress_POLYGON = '0xE3dF7076A78c0144B9E53dF4FD591cBce5f1f187'
export const WyvernAtomicizer_POLYGON = '0xd3B1a102a4CDF53eEa55dfEcf62C3b78020F625A'

export const ownerAddress = '0x683BFA37252595E24c87Fbb674F618017F47c5C3'
export const zeroAddress = '0x0000000000000000000000000000000000000000'

export const paymentTokenApproveAddress =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
export const extraMetaDataAddress =
  '0x0000000000000000000000000000000000000000000000000000000000000000'

export const orderType = [
  {
    name: 'exchange',
    type: 'address',
  },
  {
    name: 'maker',
    type: 'address',
  },
  {
    name: 'taker',
    type: 'address',
  },
  {
    name: 'makerRelayerFee',
    type: 'uint256',
  },
  {
    name: 'takerRelayerFee',
    type: 'uint256',
  },
  {
    name: 'takerCashbackFee',
    type: 'uint256',
  },
  {
    name: 'feeRecipient',
    type: 'address',
  },
  {
    name: 'side',
    type: 'uint8',
  },
  {
    name: 'saleKind',
    type: 'uint8',
  },
  {
    name: 'target',
    type: 'address',
  },
  {
    name: 'howToCall',
    type: 'uint8',
  },
  {
    name: 'data',
    type: 'bytes',
  },
  {
    name: 'replacementPattern',
    type: 'bytes',
  },
  {
    name: 'royaltyData',
    type: 'bytes',
  },
  {
    name: 'staticTarget',
    type: 'address',
  },
  {
    name: 'staticExtradata',
    type: 'bytes',
  },
  {
    name: 'paymentToken',
    type: 'address',
  },
  {
    name: 'basePrice',
    type: 'uint256',
  },
  {
    name: 'extra',
    type: 'uint256',
  },
  {
    name: 'listingTime',
    type: 'uint256',
  },
  {
    name: 'expirationTime',
    type: 'uint256',
  },
  {
    name: 'salt',
    type: 'uint256',
  },
  {
    name: 'nonce',
    type: 'uint256',
  },
]
export const domainType = [
  {
    name: 'name',
    type: 'string',
  },
  {
    name: 'version',
    type: 'string',
  },
  {
    name: 'chainId',
    type: 'uint256',
  },
  {
    name: 'verifyingContract',
    type: 'address',
  },
]

import { useAppSelector } from 'hooks/useAppSelector'
import NextImage from 'shared/components/nextImage/NextImage'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { modal_close_icon } from 'shared/icons'
import {
  ModalBody,
  ModalClose,
  ModalContent,
  ModalHeadingText,
  ModalHeadingWrapper,
} from './CustomModal.styles'

interface Props {
  headerText?: string | any
  show: boolean
  toggleModal(value: boolean): void
  children: React.ReactNode
  disableCloseButton?: boolean
  redirectingId?: string
  executeOnClose?: () => void
  alertModal?: boolean
  setAlertModal?: any
  executeOnOutSideClick?: any
  headerTextFontSize?: string
  overflow?: boolean
}

const CustomModal = (props: Props) => {
  const [lockScroll, setLockScroll] = useState(false)

  const {
    headerText,
    show,
    toggleModal,
    children,
    disableCloseButton,
    redirectingId,
    executeOnClose,
    alertModal,
    setAlertModal,
    executeOnOutSideClick,
    headerTextFontSize,
  } = props
  // useEffect(() => {
  //   show === true
  //     ? (document.body.style.overflow = 'hidden')
  //     : (document.body.style.overflow = 'null')
  // }, [show])

  const router = useRouter()
  const handleClickOutside = (e: any) => {
    setLockScroll(false)
    e.stopPropagation()
    if (!disableCloseButton) {
      e.stopPropagation()
      if (e.target === e.currentTarget) {
        e.stopPropagation()
        toggleModal(false)
      }
      if (executeOnClose) {
        e.stopPropagation()
        executeOnClose()
        setLockScroll(false)
      }
    }
  }
  // useEffect(() => {
  //   if (show) {
  //     setLockScroll(true)
  //   }
  //   if (lockScroll) {
  //     document.body.style.overflow = 'hidden'
  //   }
  //   if (!lockScroll) {
  //     document.body.style.overflow = 'auto'
  //   }
  // }, [lockScroll])

  const { theme } = useAppSelector(state => state.theme)
  return (
    <ModalBody
      // lockScroll={lockScroll}
      onClick={(e: any) => {
        e.stopPropagation()
      }}
      onMouseDown={
        disableCloseButton
          ? (e: any) => {
              e.stopPropagation()
            }
          : handleClickOutside
      }
    >
      <ModalContent themeMode={theme} overflow={props.overflow === false? false : true}>
        {headerText && (
          <ModalHeadingWrapper themeMode={theme}>
            <ModalHeadingText themeMode={theme} headerTextFontSize={headerTextFontSize}>
              {headerText}
            </ModalHeadingText>
          </ModalHeadingWrapper>
        )}
        {disableCloseButton ? null : (
          <ModalClose
            onClick={() => {
              !setAlertModal && toggleModal(!show)
              setAlertModal && setAlertModal(true)
              {
                redirectingId && router.push(`/assets/${redirectingId}`)
              }
            }}
          >
            <NextImage height={20} width={20} src={modal_close_icon} />
          </ModalClose>
        )}

        {children}
      </ModalContent>
    </ModalBody>
  )
}
export default CustomModal

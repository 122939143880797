// default module import
import React, { useContext, useState } from 'react'
import NextImage from 'shared/components/nextImage/NextImage'
// custom
// custom module import
import { Container, FlexBox } from '../../../styles/defaultStyles'
import {
  logo_dark,
  logo_light,
  facebook,
  instagram,
  youtube,
  linkedin,
  pintrest,
  twitter,
} from '../../icons'
//changes
import {
  AppFooter,
  FooterBottom,
  FooterBottomFlexBox,
  FooterBottomTitle,
  FooterLeftContainer,
  FooterLeftTextBlock,
  FooterLink,
  FooterLinkTitle,
  FooterLinkTitleTile,
  FooterRightCommunity,
  FooterRightCommunityText,
  FooterRightContainer,
  FooterRightIcons,
  FooterRightLinks,
  FooterWrapper,
  StyledLink,
} from './Footer.style'
import { useAppSelector } from 'hooks/useAppSelector'
import Link from 'next/link'
import { useGetCategoriesQuery } from 'logic/reactQuery/homePageService'
import { resourcesUrl } from 'shared/routes/routes'
import tiktok from '../../../public/assets/svg/tiktok.svg'

export const Footer = () => {
  const { theme } = useAppSelector(state => state.theme)

  const { data: categories } = useGetCategoriesQuery()

  return (
    <AppFooter>
      <Container>
        <FooterWrapper>
          <FooterLeftContainer>
            <NextImage src={theme === 'light' ? logo_light : logo_dark} alt="" className="icon" />
            <FooterLeftTextBlock>
              The world’s largest digital marketplace for crypto collectibles and non-fungible
              tokens (NFTs). Buy, sell and discover exclusive digital items.
            </FooterLeftTextBlock>
          </FooterLeftContainer>
          <FooterRightContainer>
            <FooterRightCommunity>
              <FooterRightCommunityText>Join our Community</FooterRightCommunityText>
              <FooterRightIcons>
                <a href={resourcesUrl.twitter} target="_blank">
                  <NextImage src={twitter} alt="" />
                </a>
                <a href={resourcesUrl.instagram} target="_blank">
                  <NextImage src={instagram} alt="" />
                </a>
                <a href={resourcesUrl.facebook} target="_blank">
                  <NextImage src={facebook} alt="" />
                </a>
                <a href={resourcesUrl.youtube} target="_blank">
                  <NextImage src={youtube} alt="" />
                </a>
                <a href={resourcesUrl.linkedin} target="_blank">
                  <NextImage src={linkedin} alt="" />
                </a>
                {/* <a href={resourcesUrl.pinterest} target="_blank">
                  <NextImage src={pintrest} alt="" />
                </a> */}
                <a href={resourcesUrl.tiktok} target="_blank">
                  <NextImage src={tiktok} alt="" />
                </a>
              </FooterRightIcons>
            </FooterRightCommunity>
            <FooterRightLinks>
              <FooterLink>
                <FooterLinkTitle>Marketplace</FooterLinkTitle>
                {/* <Link href="/assets">All NFTs</Link>
                <Link href="">Art</Link>
                <Link href="">Collectibles</Link>
                <Link href="">Domain Names</Link>
                <Link href="">Music</Link>
                <Link href="">Photography</Link>
                <Link href="">Sports</Link> */}
                <StyledLink themeMode={theme} href="/assets">
                  <p>All NFTs</p>
                </StyledLink>
                {categories?.map(categoriesData => (
                  <Link href={`/collection/${categoriesData.categorySlug}`}>
                    <p>{categoriesData.categoryName.toUpperCase()}</p>
                  </Link>
                ))}
              </FooterLink>
              <FooterLink>
                <FooterLinkTitle>My Account</FooterLinkTitle>
                <Link href="/profile">
                  <p>Profile</p>
                </Link>
                <Link href="/profile?tab=favourite">
                  <p>Favorites</p>
                </Link>
                <Link href="/collections">
                  <p>My Collections</p>
                </Link>
                <Link href="/settings">
                  <p>Settings</p>
                </Link>
                <Link href="/watchlist">
                  <p>Watchlist</p>
                </Link>
                <FooterLinkTitleTile>Stats</FooterLinkTitleTile>
                <Link href="/rankings">
                  <p>Rankings</p>
                </Link>
                <Link href="/activity">
                  <p>Activity</p>
                </Link>
              </FooterLink>
              <FooterLink>
                <FooterLinkTitle>Resources</FooterLinkTitle>
                {/* <Link href="https://junglenft.zohodesk.com/portal/en/home">Help Center</Link> */}
                <a href={resourcesUrl.helpCenter} target="blank">
                  <p>Help Center</p>
                </a>
                <a href={resourcesUrl.platformStatusUrl} target="_blank">
                  <p>Platform Status</p>
                </a>
                <Link href="/partner">
                  <p>Partners</p>
                </Link>
                {/* <a href="/taxes">
                  <p>Taxes</p>
                </a> */}
                <a href="/gasFree">
                  <p>Gas-Free Marketplace</p>
                </a>
                <a href="/newsLetter">
                  <p>Newsletter</p>
                </a>
              </FooterLink>
              <FooterLink>
                <FooterLinkTitle>More</FooterLinkTitle>
                <Link href="/about">
                  <p>About</p>
                </Link>
                {/* <Link href="">Grants</Link> */}
                <Link href="/faq">
                  <p>FAQ's</p>
                </Link>
                {/* <Link href="https://jungle-feedbacks.typeform.com/feedbacks">Feedback</Link> */}
                {/* <a href="https://jungle-feedbacks.typeform.com/feedbacks" target="blank">
                  <p>Feedback</p>
                </a> */}
              </FooterLink>
            </FooterRightLinks>
          </FooterRightContainer>
        </FooterWrapper>
      </Container>
      <FooterBottom themeMode={theme}>
        <FooterBottomTitle themeMode={theme}>@2022 Jungle. All rights reserved</FooterBottomTitle>
        <FooterBottomFlexBox themeMode={theme}>
          <Link href="/privacy">
            <p>Privacy Policy</p>
          </Link>
          <Link href="/termsOfService">
            <p>Terms & Agreements</p>
          </Link>
        </FooterBottomFlexBox>
      </FooterBottom>
    </AppFooter>
  )
}

import { useQuery } from 'react-query'
import { ICategory } from 'shared/interface'
import { apiEndPoints } from 'utilities/apiUrls'
import { apiRequest, axiosInstance } from 'utilities/axiosInterceptor'
import { CacheKeys } from './cacheKeys'

// Homepage Integrations

// Categories
const getCategoriesFn = () => {
  const url = apiEndPoints.user.getAllCategories
  return axiosInstance.get<ICategory[]>(url)
}

export const useGetCategoriesQuery = () => {
  return useQuery(CacheKeys.categories, getCategoriesFn, {
    refetchOnWindowFocus: false,
  })
}
// Categories NotableDrops

const getNotableDropsFn = () => {
  const url = apiEndPoints.homepage.notableDrops
  return apiRequest({ url })
}

export const useGetNotableDropsQuery = () => {
  return useQuery(CacheKeys.notableDrops, getNotableDropsFn, {
    refetchOnWindowFocus: false,
  })
}

// Categories TopTrending
const getTopTrendingFn = ({ queryKey }: any) => {
  const filterTime = queryKey[1]
  const url = `${apiEndPoints.homepage.topTrending}/${filterTime}`
  return apiRequest({ url })
}

export const useGetTopTrendingQuery = (trendingFilterTime: string) => {
  return useQuery([CacheKeys.topTrending, trendingFilterTime], getTopTrendingFn, {
    refetchOnWindowFocus: false,
  })
}

// Categories TopCollections
const getTopCollectionsFn = ({ queryKey }: any) => {
  const filterTime = queryKey[1]
  const url = `${apiEndPoints.homepage.topCollections}/${filterTime}`
  return apiRequest({ url })
}

export const useGetTopCollectionsQuery = (collectionsFilterTime: string) => {
  return useQuery([CacheKeys.topCollections, collectionsFilterTime], getTopCollectionsFn, {
    refetchOnWindowFocus: false,
  })
}

import { createSlice } from '@reduxjs/toolkit'
import { boolean } from 'yup'

interface I_InstaSlice {
  instagramData: any[]
  selectedItems: any[]
  isInstagram: boolean
}

const initialState: I_InstaSlice = {
  instagramData: [],
  selectedItems: [],
  isInstagram: false,
}

const instagramSlice = createSlice({
  name: 'instagram',
  initialState,
  reducers: {
    setInstagramData: (state: I_InstaSlice, { payload }: { payload: any }) => {
      console.log('payload', payload)
      state.instagramData = payload
    },
    setSelectedInstagramData: (state: I_InstaSlice, { payload }: { payload: any }) => {
      //   console.log('payload', payload)
      console.log('selected', payload)
      state.selectedItems = payload
    },
    setInstagramStatus: (state: I_InstaSlice, { payload }: { payload: any }) => {
      //   console.log('payload', payload)
      state.isInstagram = payload
    },
  },
})

export const { setInstagramData, setSelectedInstagramData, setInstagramStatus } =
  instagramSlice.actions
export default instagramSlice.reducer

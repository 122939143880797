import WalletToolTip from 'modules/login/WalletToolTip'
import NextImage from 'shared/components/nextImage/NextImage'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
// import jwt_decode from 'jwt-decode'
import React, { CSSProperties, useEffect, useId, useState } from 'react'
import {
  fortmatic,
  injected,
  walletConnect,
  walletLink,
  portis,
  torus,
  authereum,
} from '../wallet/connectors'
import {
  arrow_down,
  fortmaticIcon,
  jungleToken,
  metamaskIcon,
  walletConnectIcon,
  walletLinkIcon,
  copy_icon,
  portisIcon,
  torusIcon,
  magicIcon,
  authereumIcon,
} from 'shared/icons'

import { PrimaryButton, SecondaryButton } from 'styles/defaultStyles'
import {
  BalanceCardWrapper,
  BodyWrapper,
  DropdownContainer,
  DropdownHeader,
  InfoText,
  LoginWalletProvider,
  LoginWalletProviderDescription,
  LoginWalletProviderName,
  LoginWallets,
  ShowMoreButton,
  TokenBalancesWrapper,
  TotalBalance,
  TokenBalance,
  H5,
  P,
  DropdownBody,
} from './Sidebar.style'
import { useAppSelector } from 'hooks/useAppSelector'
import { checkMetamask, commaNumber, copyToClipboard, shortenAddress } from 'utilities/helpers'
import toast from 'react-hot-toast'
import { SwapAndFundModal } from 'shared/components/swapTokens/SwapAndFundModal'
import { IBalance } from 'shared/interface'
import { networkName } from 'shared/wallet/networkParams'
import { useRouter } from 'next/router'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useWeb3React } from '@web3-react/core'
import { I_LoginOptions, useLoginMutation, useUserLogoutQuery } from 'logic/reactQuery/userService'
import {
  setAccountLoading,
  setChainType,
  setIsLoggedIn,
  setUserFromLoginPage,
  setUserFromSidebar,
} from 'logic/redux/slices/userSlice'
import { LoginWalletsLayer } from 'modules/login/LoginScreen.style'
import { Spinner } from 'shared/components/spinner/Spinner'
import TippyToolTip from 'shared/components/tippyTooltip/TippyToolTip'

interface IBalanceCardProps {
  className?: string
  style?: CSSProperties
  handleClickAddFunds: () => void
  totalBalance: string | number | BigNumber
}
const BalanceCard = ({
  className,
  style,
  handleClickAddFunds,
  totalBalance,
}: IBalanceCardProps) => {
  const { theme } = useAppSelector(state => state.theme)
  return (
    <>
      <BalanceCardWrapper themeMode={theme} className={className} style={style}>
        <TotalBalance>
          <p className="item balance">Total Balance</p>
          {Number(totalBalance).toFixed(4).includes('e') ? (
            <TippyToolTip
              placement="top"
              toolTipContent={<div>{Number(totalBalance).toFixed(4)}</div>}
            >
              <h3 className="item overflowBal">
                ${commaNumber(new BigNumber(totalBalance).toFixed(4))}
              </h3>
            </TippyToolTip>
          ) : (
            <h3 className="item">${commaNumber(Number(totalBalance).toFixed(4))}</h3>
          )}
          <PrimaryButton onClick={handleClickAddFunds}>Add Funds</PrimaryButton>
        </TotalBalance>
      </BalanceCardWrapper>
    </>
  )
}

interface ITokenBalanceProps {
  className?: string
  style?: CSSProperties
  balances: IBalance[]
}

const TokenBalances = ({ className, style, balances }: ITokenBalanceProps) => {
  const { theme } = useAppSelector(state => state.theme)
  return (
    <TokenBalancesWrapper className={className} style={style}>
      {balances.map(item => (
        <TokenBalance themeMode={theme} key={item?.token?.tokenDetails?.id}>
          <div className="flex justify-between p-16">
            <div className="flex items-center">
              <NextImage
                src={item?.token?.tokenDetails?.imageUrl}
                width={18}
                height={25}
                layout="fixed"
                alt="token"
              />
              <div className="ml-8">
                <H5>{item?.token?.tokenDetails?.symbol.toUpperCase()}</H5>
                <P>{networkName[Number(item?.token?.tokenDetails?.chainId)]}</P>
              </div>
            </div>
            <div>
              {Number(item.balance).toFixed(4).includes('e') ? (
                <TippyToolTip
                  placement="top"
                  toolTipContent={<div>{Number(item.balance).toFixed(4)}</div>}
                >
                  <H5 className="overflowBal">
                    {commaNumber(new BigNumber(item.balance).toFixed(4))}
                  </H5>
                </TippyToolTip>
              ) : (
                <H5>{Number(item.balance) === 0 ? Number(item.balance) : Number(item.balance).toFixed(4)}</H5>
              )}
              {Number(item.usdBalance).toFixed(4).includes('e') ? (
                <TippyToolTip
                  placement="bottom"
                  toolTipContent={<div>{Number(item.usdBalance).toFixed(4)}</div>}
                >
                  <P className="overflowBal">
                    ${commaNumber(new BigNumber(item.usdBalance).toFixed(4))}
                  </P>
                </TippyToolTip>
              ) : (
                <P>${commaNumber(Number(item.usdBalance).toFixed(4))}</P>
              )}
            </div>
          </div>
        </TokenBalance>
      ))}
    </TokenBalancesWrapper>
  )
}

interface ISidebarProps {
  balances: IBalance[]
  dropdown?: boolean
  setDropdown?: any
}
const Sidebar = ({ balances, dropdown, setDropdown }: ISidebarProps) => {
  const [showSwapModal, setShowSwapModal] = useState(false)
  const [showMoreOptions, setShowMoreOptions] = useState<boolean>(false)
  const { theme } = useAppSelector(state => state.theme)
  const [walletIndex, setWalletIndex] = useState<any>(null)
  const { mutateAsync: mutateLogoutUser } = useUserLogoutQuery()

  const handleWalletIndex = (i: number) => {
    setWalletIndex(i)
  }
  const onCopy = (text: string) => {
    copyToClipboard(text)
    toast.success('Copied')
  }
  const walletsInfo = [
    {
      id: useId(),
      icon: fortmaticIcon,
      walletName: 'Email',
      connector: 'fortmatic',
    },
    {
      id: useId(),
      icon: metamaskIcon,
      walletName: 'Metamask',
      description: 'Popular',
      connector: 'injected',
    },
    {
      id: useId(),
      icon: walletConnectIcon,
      walletName: 'WalletConnect',
      connector: 'walletConnect',
    },
    {
      id: useId(),
      icon: walletLinkIcon,
      walletName: 'Coinbase',
      connector: 'walletLink',
    },
    {
      id: useId(),
      icon: torusIcon,
      walletName: 'Torus',
      connector: 'torus',
    },

    {
      id: useId(),
      icon: portisIcon,
      walletName: 'Portis',
      connector: 'portis',
    },
    {
      id: useId(),
      icon: authereumIcon,
      walletName: 'Authereum',
      connector: 'authereum',
    },
  ]
  const [walletConnectLoading, setWalletConnectLoading] = useState<boolean>(false)
  const { activate, account, active, deactivate, error, chainId, library } = useWeb3React()
  const { mutateAsync, isLoading, data: loginInfo, error: loginError }: any = useLoginMutation()
  const dispatch = useAppDispatch()
  const { isLoggedIn, userFromSidebar } = useAppSelector(state => state.user)

  const providers: any = {
    fortmatic,
    injected,
    walletConnect,
    walletLink,
    portis,
    torus,
    authereum,
  }

  const setProvider = (connector: string) => {
    typeof window !== 'undefined' && window.localStorage.setItem('provider', connector)
  }

  const connectWallet: any = (connector: string) => {
    dispatch(setUserFromSidebar(true))
    setWalletConnectLoading(true)
    activate(providers[connector], () => {
      typeof window !== 'undefined' && window.localStorage.removeItem('provider')
    })
    setProvider(connector)
  }

  const disconnectWallet: any = async () => {
    try {
      const response = await mutateLogoutUser()
      if (response?.success) {
        const isExistingUser =
          typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
        const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
        const userAuthInfoUpdated = userAuthInfo.filter(
          (user: any) => user.walletAddress !== account,
        )
        if (userAuthInfoUpdated) {
          const newUserInfo = [...userAuthInfoUpdated]
          typeof window !== 'undefined' &&
            window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
        }
        if (typeof window !== 'undefined') {
          window.localStorage.removeItem('provider')
          window.localStorage.removeItem('accessToken')
          window.localStorage.removeItem('tokenExpiry')
        }
        deactivate()
        dispatch(setIsLoggedIn(false))
        dispatch(setChainType(''))
        dispatch(setUserFromLoginPage(false))
        dispatch(setAccountLoading(false))
        dispatch(setUserFromSidebar(false))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const userSignatureAuthorization = async (userAddress: string) => {
    try {
      const signatureMessage =
        'action= signIn&' +
        'address' +
        userAddress +
        'signUp' +
        '&timestamp=' +
        new Date()?.toISOString()

      const signatureHash = await library.eth.personal.sign(signatureMessage, userAddress)
      const data: I_LoginOptions = {
        walletAddress: userAddress,
        signature: signatureHash,
        signature_message: signatureMessage,
      }
      const response = await mutateAsync(data)
      if (response?.message?.access_token) {
        dispatch(setIsLoggedIn(true))
        if (chainId) dispatch(setChainType(chainId))

        const userInfo = [
          {
            walletAddress: userAddress,
            accessToken: response?.message?.access_token,
            tokenExpiry: response?.message?.tokenExpirationDate,
          },
        ]
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'accessToken',
            JSON.stringify(response?.message?.access_token),
          )
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'tokenExpiry',
            JSON.stringify(response?.message?.tokenExpirationDate),
          )

        const isExistingUser =
          typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
        const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null

        if (userAuthInfo !== undefined && userAuthInfo !== null && userAuthInfo?.length > 0) {
          const userAuthInfoUpdated = userAuthInfo.filter(
            (user: any) => user.walletAddress !== userAddress,
          )
          if (userAuthInfoUpdated) {
            const newUserInfo = [...userAuthInfoUpdated, ...userInfo]
            typeof window !== 'undefined' &&
              window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
            dispatch(setUserFromSidebar(false))
          }
        } else {
          typeof window !== 'undefined' &&
            window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
        }

        // @ts-ignore window.analytics undefined below
        window.analytics.track('User Authorized', {
          data,
        })
        // @ts-ignore
        toast.success('Wallet Connected')
      } else {
        // @ts-ignore window.analytics undefined below
        window.analytics.track('User UnAuthorized', {
          data,
        })
      }
    } catch (error: any) {
      setWalletConnectLoading(false)
      console.log(error?.message)
      toast(error?.message)
      // @ts-ignore window.analytics undefined below
      window.analytics.track('User UnAuthorized', {
        account,
      })
      disconnectWallet()
    }
  }

  const checkUserStatus = (address: string): boolean => {
    const isExistingUser = typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
    const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
    if (userAuthInfo !== undefined && userAuthInfo !== null && userAuthInfo?.length > 0) {
      const userAuthInfoUpdated: any = userAuthInfo.find(
        (user: any) => user?.walletAddress?.toLowerCase() === address?.toLowerCase(),
      )
      if (userAuthInfoUpdated !== undefined && userAuthInfoUpdated !== null) {
        // const userToken: any = jwt_decode(userAuthInfoUpdated?.accessToken)
        // const isExpired = dayjs.unix(userToken?.exp).diff(dayjs()) < 1
        const expirationDate = userAuthInfoUpdated?.tokenExpiry
        // let isExpired = (new Date().getTime()) < expirationDate
        const isExpired = dayjs.unix(expirationDate).diff(dayjs()) < 1
        if (!isExpired) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    if (account && !isLoggedIn && userFromSidebar) {
      const userHasValidToken = checkUserStatus(account)
      if (!userHasValidToken) {
        userSignatureAuthorization(account)
      } else {
        dispatch(setUserFromSidebar(false))
        dispatch(setIsLoggedIn(true))
        if (chainId) dispatch(setChainType(chainId))
        toast.success('Wallet Connected')
        // @ts-ignore window.analytics undefined below
        window.analytics.track('User Authorized', {
          account,
        })
      }
    }
  }, [account, isLoggedIn, userFromSidebar])

  useEffect(() => {
    if (error || loginError) {
      setWalletConnectLoading(false)
      toast.error(error?.message || loginError?.message)
    }
  }, [error, loginError])

  useEffect(() => {
    if (isLoading) {
      setWalletConnectLoading(true)
    }
  }, [isLoading])

  const [magicLogin, setMagicLogin] = useState<boolean>(false)
  const [magicUserWalletAddress, setMagicUserWalletAddress] = useState<any>('')
  const [magicError, setMagicError] = useState<boolean>(false)
  const [magicLoading, setMagicLoading] = useState<boolean>(false)

  useEffect(() => {
    if (magicUserWalletAddress) {
      const emailReg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      const isValid = emailReg?.test(magicUserWalletAddress)
      if (isValid) {
        setMagicError(false)
      } else {
        setMagicError(true)
      }
    }
  }, [magicUserWalletAddress])

  useEffect(() => {
    if (magicLoading) {
      setTimeout(() => {
        setMagicLoading(false)
      }, 2000)
    }
  }, [magicLoading])

  return (
    <DropdownBody onClick={() => setDropdown(!dropdown)}>
      <DropdownContainer
        onClick={(e: any) => e.stopPropagation()}
        dropdown={dropdown}
        themeMode={theme}
      >
        <DropdownHeader themeMode={theme}>
          <div className="flex items-center">
            <NextImage src={jungleToken} alt="" className="img-icon" />
            <h4>My Wallet</h4>
            <NextImage src={arrow_down} />
          </div>
          {account && isLoggedIn && (
            <div className="flex items-center">
              <span className="wallet-address">{shortenAddress(account)}</span>
              <button className="copy-btn" onClick={() => onCopy(account)}>
                <NextImage src={copy_icon} />
              </button>
            </div>
          )}
        </DropdownHeader>
        {!isLoggedIn ? (
          <>
            <div>
              {/* <InfoText themeMode={theme}>
                Connect with one of our available
                <TippyToolTip placement="top" toolTipContent={<WalletToolTip />}>
                  <span>wallet</span>
                </TippyToolTip>
                providers or create a new one.
              </InfoText> */}
            </div>
            <BodyWrapper>
              <LoginWallets showMoreOptions={showMoreOptions}>
                {/* <LoginWalletProvider
                  themeMode={theme}
                  onClick={
                    walletConnectLoading || isLoading
                      ? null
                      : () => {
                          setMagicLogin(true)
                        }
                  }
                >
                  {magicLoading && <LoginWalletsLayer></LoginWalletsLayer>}
                  <NextImage width={33} height={29} src={magicIcon} alt="magic"></NextImage>
                  <LoginWalletProviderName>Email</LoginWalletProviderName>
                  <LoginWalletProviderDescription>
                    {(magicLoading || isLoading) && <Spinner />}
                  </LoginWalletProviderDescription>
                </LoginWalletProvider> */}
                {walletsInfo.map((wallet: any, i: number) => (
                  <LoginWalletProvider
                    themeMode={theme}
                    onClick={
                      walletConnectLoading || isLoading
                        ? null
                        : () => {
                          if (wallet.walletName === 'Metamask') {
                            checkMetamask() ? connectWallet(wallet.connector) : null
                          } else {
                            connectWallet(wallet.connector)
                            handleWalletIndex(i)
                          }
                        }
                    }
                  >
                    {(walletConnectLoading || isLoading) && <LoginWalletsLayer></LoginWalletsLayer>}
                    <NextImage width={33} height={29} src={wallet.icon} alt={wallet.walletName} />
                    <LoginWalletProviderName>
                      {wallet.walletName === 'Metamask' ? (
                        checkMetamask() ? (
                          wallet.walletName
                        ) : (
                          <p onClick={() => window.open('https://metamask.io/download/')}>
                            Install Metamask
                          </p>
                        )
                      ) : (
                        wallet.walletName
                      )}
                    </LoginWalletProviderName>
                    {walletIndex === i &&
                      (wallet.description || walletConnectLoading || isLoading) ? (
                      <LoginWalletProviderDescription>
                        {walletConnectLoading || isLoading ? <Spinner /> : wallet.description}
                      </LoginWalletProviderDescription>
                    ) : null}
                  </LoginWalletProvider>
                ))}
              </LoginWallets>
            </BodyWrapper>
            <ShowMoreButton>
              <SecondaryButton
                width="251px"
                height="50px"
                onClick={() => setShowMoreOptions((prev: boolean) => !prev)}
              >
                {showMoreOptions ? 'Show Less Options' : 'Show More Options'}
              </SecondaryButton>
            </ShowMoreButton>
          </>
        ) : (
          account &&
          isLoggedIn && (
            <>
              <BalanceCard
                className="balance-card p-16"
                handleClickAddFunds={() => {
                  setShowSwapModal(true)
                }}
                totalBalance={balances.reduce((acc, cur) => acc + Number(cur.usdBalance), 0)}
              />
              <TokenBalances balances={balances} className="balance-card" />
            </>
          )
        )}
      </DropdownContainer>
      {showSwapModal && (
        <SwapAndFundModal show={showSwapModal} toggleModal={setShowSwapModal} showAddFund />
      )}
    </DropdownBody>
  )
}

export default Sidebar

export const resourcesUrl = {
  platformStatusUrl: 'https://status-junglenft.dev.rapidinnovation.tech/#',
  helpCenter: 'https://junglenft.zohodesk.com/portal/en/home',
  linkedin: 'https://linkedin.com/company/junglenfts/',
  twitter: 'https://twitter.com/Junglenftmarket',
  // discord: 'https://discord.com/invite/tQzEMNhMfV',
  discord: 'https://discord.gg/bDnPrw5tXV',
  instagram: 'https://www.instagram.com/jungle_marketplace/ ',
  facebook: 'https://www.facebook.com/junglenft/',
  pinterest: 'http://example.com/',
  youtube: 'https://www.youtube.com/channel/UCRYvVRqEQ-E3z-x_cXglI4w',
  reddit: 'http://example.com/',
  raiseTicket: 'https://junglenft.zohodesk.com/portal/en/newticket',
  ipfs: 'https://ipfs.io/',
  tiktok: 'https://tiktok.com/@junglenftmarket/',
}

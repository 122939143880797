// ! ALL THE API ROUTES STARTING WITH /api/v1/collections

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { CacheKeys } from './cacheKeys'
import { axiosInstance } from 'utilities/axiosInterceptor'
import { apiEndPoints } from 'utilities/apiUrls'
import { generateQueryString } from 'utilities/helpers'
import toast from 'react-hot-toast'

// export interface ISellData {
//   auction_items?: string
//   auction_collection: string
//   startDate?: any | number
//   endDate?: any | number
//   tokens?: string
//   auctionType?: string
//   isBundle?: boolean
//   name?: string
//   description?: string
//   isReservedAuction?: boolean
//   walletAddress?: string
//   quantity?: any | number
//   startingPrice?: any | number
//   endingPrice?: any | number
//   reservedPrice?: any | number
//   timedAuctionMethod?: string
// }

export interface I_createAuctionFn {
  auction_item?: string | null
  bundle_items?: [string] | null
  auction_collection?: string
  startDate?: number
  endDate?: number
  tokens?: string | null
  auctionType?: string | null
  bundle?: {
    isBundle?: boolean
    name?: string | null
    description?: string | null
  }
  reservedAuction?: {
    isReservedAuction?: boolean
    walletAddress?: string | null
  }
  quantity?: number
  startingPrice?: number
  endingPrice?: number | null
  reservedPrice?: number | null
  timedAuctionMethod?: string
  signatureObject?: {
    maker?: string
    exchange?: string
    taker?: string
    makerRelayerFee?: number
    takerRelayerFee?: number
    takerCashbackFee?: number
    feeRecipient?: string
    side?: number
    saleKind?: number
    target?: string
    howToCall?: number
    data?: string
    replacementPattern?: string
    royaltyData?: string
    staticTarget?: string
    staticExtradata?: string
    paymentToken?: string
    basePrice?: number
    extra?: number
    listingTime?: number
    expirationTime?: number
    salt?: number
    nonce?: number
  }
  signature?: string
}

interface IUpdateAuctionOptions {
  auctionId: string
  endDate?: number
  price: number
  signatureObject?: {
    maker: string
    exchange: string
    taker: string
    makerRelayerFee: number
    takerRelayerFee: number
    takerCashbackFee: number
    feeRecipient: string
    side: number
    saleKind: number
    target: string
    howToCall: number
    data: string
    replacementPattern: string
    royaltyData: string
    staticTarget: string
    staticExtradata: string
    paymentToken: string
    basePrice: number | string
    extra: number
    listingTime: number
    expirationTime: number
    salt: number
    nonce: number
  }
  signature: string
}

// Get details of a particular NFT auction
const getAuctionDetails = (nftId?: string | null) => {
  const url = `${apiEndPoints.auctions.getAuctionsByItem}/${nftId}`
  return axiosInstance.get(url)
}

export const useGetAuctionDetailsQuery = (nftId?: string | null) => {
  return useQuery([CacheKeys.auctions, nftId], () => getAuctionDetails(nftId), {
    enabled: !!nftId,
    select: data => {
      let result: Array<Object>
      if (data?.message.length > 0) {
        result = data?.message.map((item: any) => {
          const { startingPrice, endDate, creator, tokens } = item
          return {
            price: startingPrice,
            usdPrice: startingPrice,
            expiration: endDate,
            from: creator?.walletAddress,
            symbol: tokens?.symbol,
            image: tokens?.imageUrl,
          }
        })
      } else {
        result = []
      }
      return result
    },
  })
}
const createAuctionFn = (options: I_createAuctionFn) => {
  const url = apiEndPoints.auctions.create
  return axiosInstance.post(url, options)
}

export const useCreateAuctionFnMutation = () => {
  return useMutation(createAuctionFn, {
    onSuccess: data => {},
  })
}

export interface I_cancelAuctionFn {
  auctionId: string
  transactionHash: string
  transactionUrl: string | undefined
}

const cancelAuctionFn = (options: I_cancelAuctionFn) => {
  const url = apiEndPoints.auctions.cancel
  return axiosInstance.patch(url, options)
}

export const useCancelAuctionFnMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(cancelAuctionFn, {
    onSuccess: () => {
      toast('Listing successfully cancelled')
      queryClient.invalidateQueries(CacheKeys.assets)
      queryClient.invalidateQueries(CacheKeys.nftActivityDetails)
    },
  })
}

const updateAuctionFn = (options: IUpdateAuctionOptions) => {
  const url = apiEndPoints.auctions.update
  return axiosInstance.post(url, options)
}

export const useUpdateAuctionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(updateAuctionFn, {
    onSuccess: data => {
      queryClient.invalidateQueries(CacheKeys.assets)
      queryClient.invalidateQueries(CacheKeys.nftActivityDetails)
    },
  })
}

export interface I_GetAuctionsByUserParams {
  walletAddress?: string | null | undefined
  isActive?: boolean
  collectionId?: string | null
}
const getAuctionsByUser = (params: I_GetAuctionsByUserParams) => {
  const url = `${apiEndPoints.auctions.getAuctionsByUser}/${params.walletAddress}/${
    params.isActive
  }?${generateQueryString({ collectionId: params.collectionId })}`
  return axiosInstance.get(url)
}

export const useGetAuctionsByUser = (params: I_GetAuctionsByUserParams) => {
  return useQuery([CacheKeys.auctions, params], () => getAuctionsByUser(params), {
    enabled: !!params.walletAddress,
  })
}

// Get details of a active Auctions of a particular NFT
const getActiveAuctionDetails = (nftId?: string | null) => {
  const url = `${apiEndPoints.auctions.getActiveAuctionsByItem}/${nftId}`
  return axiosInstance.get(url)
}

export const useGetActiveAuctionDetailsQuery = (nftId?: string | null) => {
  return useQuery([CacheKeys.activeAuctions, nftId], () => getActiveAuctionDetails(nftId), {
    enabled: !!nftId,
    select: data => {
      let result: Array<Object>
      if (data?.message.length > 0) {
        result = data?.message.map((item: any) => {
          const {
            id,
            startingPrice,
            endDate,
            tokens,
            signatureObject,
            auction_item,
            creator,
            currentPrice,
            reservedAuction,
            quantity,
          } = item
          return {
            price: startingPrice !== null && startingPrice ? startingPrice : currentPrice,
            usdPrice: {
              value: startingPrice !== null && startingPrice ? startingPrice : currentPrice,
              symbol: tokens?.symbol,
            },
            expiration: endDate,
            from: creator?.walletAddress,
            symbol: tokens?.symbol,
            image: tokens?.imageUrl,
            auction_item: item,
            creator: creator,
            opensea: item,
            usdOpenSeaPrice: item,
            reservedAuction: reservedAuction,
            quantity: quantity,
          }
        })
      } else {
        result = []
      }
      return result
    },
  })
}

// Cancel all active auctions and offers of a particular user

const cancelAllListingsFn = () => {
  const url = apiEndPoints.auctions.cancelAllListings
  return axiosInstance.patch(url)
}

export const useCancelAllListingsMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(cancelAllListingsFn, {
    onSuccess: data => {
      if (data?.success) {
        queryClient.invalidateQueries(CacheKeys.auctions)
        queryClient.invalidateQueries(CacheKeys.offers)
        toast.success('All listings and offers successfully cancelled')
      }
    },
    onError: () => {
      toast.success('cancelling listings and offers failed')
    },
  })
}

// Get Auction Winners
const getAuctionWinner = () => {
  const url = `${apiEndPoints.auctions.auctionsWon}`
  return axiosInstance.get(url)
}

export const useGetAuctionWinner = (isLoggedIn: boolean) => {
  return useQuery([CacheKeys.auctionsWon], () => getAuctionWinner(), {
    enabled: !!isLoggedIn,
  })
}

// Acknowledge Auctions Won
export interface I_AcknowledgeAuction {
  id: Array<string>
}

const AcknowledgeAuctionWonFn = (options: I_AcknowledgeAuction) => {
  const url = `${apiEndPoints.auctions.acknowledgeAuctionsWon}`
  return axiosInstance.patch(url, options)
}

export const useAcknowledgeAuctionWon = () => {
  return useMutation(AcknowledgeAuctionWonFn, {})
}

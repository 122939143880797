export const apiEndPoints = {
  homepage: {
    categories: '/category',
    notableDrops: '/collections/collection/fetchNotableDrops',
    topTrending: '/toptrending',
    topCollections: '/topCollections',
  },

  chains: {
    getChains: '/chains/user',
  },
  tokens: {
    getTokens: '/tokens/user',
    getAllTokens: '/tokens',
  },
  nftItem: {
    getNftItem: '/nft-item',
    createNftItem: '/nft-item/create',
    allItems: '/nft-item/allItems',
    addOrRemoveFavoriteNftItem: '/nft-item/favourites',
    userFavoriteNfts: '/nft-item/getFavouriteItems',
    deleteNftItem: '/nft-item/delete',
    transferNftItem: '/nft-item/transfer',
    updateViewer: '/nft-item/updateViewer',
    addNftItemFavourite: '/nft-item/favourites',
    getNftItemFavouriteCount: '/nft-item/getFavouriteItemsCount',
    report: '/report',
    getUserHiddenNftItems: '/nft-item/getHiddenItems',
    hideUserNftItem: '/nft-item/hideitem',
    updateSingleNFtItem: '/nft-item',
    deleteNFTItem: '/nft-item/delete',
    getPriceHistory: '/nft-item/priceHistory',
    pinNftItem: 'nft-item/pinNftItem',
    freezeMetadata: '/nft-item/freezeMetadata',
    buyNft: '/nft-item/buyItem',
    createBatchNftItem: '/nft-item/createBatchNftItem',
  },
  collections: {
    root: '/collections',
    create: '/collections/create',
    uniqueNameOrUrl: '/collections/checkUniqueCollection',
    getUserCollections: '/collections/getByUserId',
    updateCollection: '/collections/update',
    updateCollaborators: '/collections/collaborators',
    getWatchCollections: '/collections/getWatchCollections',
    addOrRemoveToWatchList: '/collections/watchlist',
    getCollectionsByCategory: '/collections/getByCategory',
    fetchRankings: '/collections/collection/fetchRankings',
    getTrendingCollections: '/collections/collections/trendingCollections',
    getTopCollections: '/collections/collections/topCollections',
    getPriceHistoryCollections: '/collections/collectionActivity',
    getImportedSmartContractCollections: '/collections/importContract',
    getCollectionList: '/collections/collectionListfromCollectedItem',
    getCreatorEarnings: 'collections/collection/fetchCreatorEarnings',
    getCreatorEarningsStats: 'collections/creatorEarningStats',
    claimCreatorEarning: 'collections/claimCreatorEarning',
    categoriesDetails: 'collections/categorydetails',
  },
  user: {
    root: '/user',
    getUser: '/user/userdetails',
    getUserDetailsByWalletaddress: '/user/userdetails/walletaddress',
    getPresignedURL: '/user/getPresignedURL',
    getAllCategories: '/user/getAllCategories',
    getProfileCount: '/user/getProfileCountStats',
    subscribeNewsLetter: '/user/subscribeNewsletter',
    nftProfilePic: '/user/setNftAsProfilePic',
    getUserCollections: '/collections/getByUserId',
    collectionMinPrice: '/offer/updateCollectionOfferPrice',
    verifyEmail: '/user/verifyEmail',
    resendEmail: '/user/resendEmailForVerification',
    kycStatus: '/kyc/kyc_status',
    logout: '/user/logout',
    uniqueUser: '/user/uniqueUser',
    kycCreateSession: '/kyc/create_session',
    kycUpdateSession: '/kyc/update_session',
    checkIsUserBlocked: '/user/checkisuserblocked',
    cashbackForUser: '/user/getcashbackforuser',
    cashbackAmountForUser: '/user/getcashbackamountforuser',
    getUserWalletBalance: '/user/getuserwalletbalance',
    userDetails: '/user/userdetails',
    syncProfile: '/user/syncProfileNFT',
    csrfToken: '/user/genCT',
  },

  notification: {
    root: '/notification/me',
  },
  offers: {
    createNewOffer: '/offer',
    updateOffer: '/offer',
    deleteOffer: '/offer',
    getAllOffers: '/offer/getOffers',
    getOffersByUser: '/offer/getOffersByUser',
    acceptOffer: '/offer/acceptOffer',
  },
  assets: {
    root: '/nft-item',
    getNftItem: '/nft-item/getNftItemByID',
    fetchFromCollection: '/nft-item/fetchFromCollection',
    metaData: 'nft-item/metadata',
    unlockableContent: '/nft-item/getUnlockableContent',
    fetchFromCollectionForUser: '/nft-item/fetchFromCollectionForUser',
  },
  activity: {
    getCollectionActivity: '/activity',
    getNftItemActivity: '/activity',
    getUserActivities: '/activity/user',
    getAllActivities: '/activity',
  },
  auctions: {
    create: '/auctions/create',
    getAuctionsByItem: '/auctions/item',
    cancel: '/auctions/cancel',
    update: '/auctions/update',
    getAuctionsByUser: '/auctions/user',
    getActiveAuctionsByItem: '/auctions/activelisting',
    cancelAllListings: '/auctions/cancelalllisting',
    auctionsWon: '/auctions/highestbids',
    acknowledgeAuctionsWon: '/auctions/updatehighestbid',
  },
  admin: {
    executeMetaTransaction: '/admin/executeMetaTransaction',
    cashbackPlatformFee: '/admin/getCashbackPlatformfee',
  },
  helpCenter: {
    getTermCondition: '/help-centre/getTermCondition',
    getAboutUs: '/help-centre/getAboutUs',
    getFrequentQuestions: '/help-centre/getFrequentlyQuestion',
    getPrivacy: '/help-centre/getPrivacy',
  },
  platform: {
    getPlatformFee: '/status',
  },
}

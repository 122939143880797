import { useWeb3React } from '@web3-react/core'
import { useAppSelector } from 'hooks/useAppSelector'
import { ModalWrapper, SwitchModalText } from 'modules/platformStatus/PlatformStatusScreen'
import React, { useState } from 'react'
import CustomModal from 'shared/components/customModal/CustomModal'
import { networkParams } from 'shared/wallet/networkParams'
import { PrimaryButton, SecondaryButton } from 'styles/defaultStyles'
import { toHex } from 'web3-utils'
import { networkName } from 'shared/wallet/networkParams'
import styled from 'styled-components'
import { media, screenSizes } from 'utilities/helpers'
import { CancelButton } from 'styles/sharedStyles'
import { Spinner } from 'shared/components/spinner/Spinner'
import {
  fortmatic,
  injected,
  walletConnect,
  walletLink,
  portis,
  torus,
  authereum,
  ETHEREUM_TESTNET_CHAIN_ID,
  POLYGON_TESTNET_CHAIN_ID,
} from '../../shared/wallet/connectors'

export const ButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  place-items: center;
  margin: 35px 0 8px 0px;
  ${media.sm`
     grid-template-columns: 1fr;
  `}
`
interface I_SwitchNetworkModal {
  showSwitchModal: boolean
  networkId: number | string
  setShowSwitchModal: any
  callback?: () => void
}

const SwitchNetworkModal = ({
  showSwitchModal,
  setShowSwitchModal,
  networkId,
  callback,
}: I_SwitchNetworkModal) => {
  const providers: any = {
    fortmatic,
    injected,
    walletConnect,
    walletLink,
    portis,
    torus,
    authereum,
  }
  const { library, activate } = useWeb3React()
  // let provider: any
  // if (typeof window !== 'undefined') {
  //   provider = localStorage.getItem('provider')
  // }
  // if (networkId === undefined) {
  //   activate(providers[provider])
  // }

  const { theme } = useAppSelector(state => state.theme)
  const { chainType } = useAppSelector(state => state.user)

  const [loader, setLoader] = useState<boolean>(false)

  const switchNetwork = async () => {
    try {
      setLoader(true)
      await library.currentProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHex(networkId ?? chainType) }],
      })
      callback && callback()
      setLoader(false)
    } catch (switchError: any) {
      console.log(switchError)
      if (switchError.code === 4902) {
        try {
          await library.currentProvider.request({
            method: 'wallet_addEthereumChain',
            params: [networkParams[toHex(networkId ?? chainType)]],
          })
          callback && callback()
          setLoader(false)
        } catch (error: any) {
          setLoader(false)
          console.error(error)
        }
      }
    }
  }
  return (
    <CustomModal
      show={showSwitchModal}
      headerText={`Please switch to ${networkName[networkId ?? chainType]} network`}
      toggleModal={setShowSwitchModal}
    >
      <ModalWrapper>
        <SwitchModalText themeMode={theme}>
          Please switch to {networkName[networkId ?? chainType]} network within your wallet.{' '}
        </SwitchModalText>
        <ButtonWrap>
          <SecondaryButton onClick={() => setShowSwitchModal(false)}>Cancel</SecondaryButton>
          <PrimaryButton onClick={switchNetwork}>
            {loader ? <Spinner /> : 'Switch Network'}
          </PrimaryButton>
        </ButtonWrap>
      </ModalWrapper>
    </CustomModal>
  )
}

export default SwitchNetworkModal

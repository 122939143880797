import { ICustomColors } from 'shared/interface'
import { DefaultTheme } from 'styled-components'

const themeDark: DefaultTheme = {
  theme: 'dark',
  white: '#ffffff',
  body_bg: '#000',
  bgTertiary: '#2C2C36',
  buttonHover: '#4B4F5B',
  textColor: '#B9B9B9',
  text: '#FFFFFF',
  textSecondary: '#D1D1D1',
  textTertiary: '#7A798A',
  brandColor: '#29AB87',
  borderPrimary: '#6FFF4B',
  borderColor: '#6b6b6b',
  sectionTitleColor: '#ffffff',
  categoriesCardBg: '#0c3328',
  categoriesCardFooterBg: '#262626',
  footerBg: '#131313',
  buttonShadow: '0px 0px 15px #29ab86c1',
  buttonShadowSecondary: '0px 0px 30px #ffffffc1',
  createAndSellCardBg: '#3D3D3D',
  createAndSellCardIconBg: '#FFFFFF',
  trendingCardBg: '#161616',
  accordionHeaderBg: '#1C1C24',
  accordionBodyBg: '#2C2C36',
  TextHover: 'darkGreen',
  aiWarning: '#F8DC4B',
  hover: '#292222',
  border: 'transparent',
  inputHover: 'rgba(41, 171, 135, 0.1)',
  cardShadow: '0px 2px 26px #5A5858',
  assetDetail: {
    background: '#1C1C24',
    backArrowBg: '#2C2C36',
    instaIconBg: 'rgba(45, 48, 61, 0.2)',
    optionsIconBg: 'rgba(255, 255, 255, 0.14)',
    propertyBorder: '#29AB87',
    propertyFooter: '#262626',
    cardBack: '#1C1C24',
    cardTitle: '#A5A5A5',
    cardDes: '#FFFFFF',
    cardLine: '#111118',
  },
  customMultiSelect: {
    background: '#1C1C24',
  },
  warning: '#EE5253',
  auctionWinnerModalBorder: '#68687D',
  auctionWinnerLink: '#97CBFF',
  bgColor: '#4c505c',
  hoverShadow: '#5A5858',
  buttonColor: '#545454',
  customColors: {
    background: '#310404',
    loader: '#2b3641',
  },
}
const themeLight: DefaultTheme = {
  theme: 'light',
  white: '#ffffff',
  body_bg: '#FFFFFF',
  bgTertiary: '#FBFBFD',
  buttonHover: '#F0FFFB',
  text: '#000000',
  textSecondary: '#000000',
  textTertiary: '#7A798A',
  textGrey: '#8A8A8A',
  brandColor: '#29AB87',
  borderPrimary: '#6FFF4B',
  borderColor: '#E6E8EC',
  sectionTitleColor: '#000000',
  categoriesCardBg: '#eaf7f3',
  categoriesCardFooterBg: '#29AB87',
  footerBg: '#ffffff',
  buttonShadow: '0px 0px 15px #29AB87',
  buttonShadowSecondary: '0px 0px 30px #29ab86c1',
  createAndSellCardBg: '#FFFFFF',
  createAndSellCardIconBg: '#29AB87',
  trendingCardBg: '#F1FFFB',
  TextHover: 'darkGreen',
  accordionHeaderBg: '#F1F1F5',
  accordionBodyBg: '#FCFCFF',
  aiWarning: '#F8DC4B',
  hover: '#e1e1eb',
  border: '#E6E8EC',
  cardShadow: '0px 0px 18px #74837F',
  inputHover: 'rgba(41, 171, 135, 0.1)',
  assetDetail: {
    background: '#F1F1F5',
    backArrowBg: '#ECECEE',
    instaIconBg: 'rgba(45, 48, 61, 0.2);',
    optionsIconBg: '#ECECEE',
    propertyBorder: '#29AB87',
    propertyFooter: '#64C2A9',
    cardBack: '#FFFFFF',
    cardTitle: '#A5A5A5',
    cardDes: '#000000',
    cardLine: '#EEEAEA',
  },
  customMultiSelect: {
    background: '#F1F1F5',
  },
  warning: '#EE5253',
  auctionWinnerModalBorder: '#E5E8EB',
  auctionWinnerLink: '#297DD0',
  hoverShadow: '#74837F',
  borderDark: '#8d8d91',
  customColors: {
    background: '#F7F7FA',
  },
}

const customColors: ICustomColors = {
  errorText: '#d21f1f',
  redText: '#ff0000',
  bgLight: '#F9F9F9',
  greyText: '#656262',
  greenGradient: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #25463d 100%)',
  lightGrey: '#ccc',
  bgDark: '#68687D',
  textColor: '#979797',
  textSecondary: '#a5a5a5',
  hoverText: '#058570',
  borderLine: '#3B3B45',
  bgCreatorEarning: '#272730',
  placeholderText: '#6d6d6e'
}
export { themeDark, themeLight, customColors }

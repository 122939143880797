import styled, { css } from 'styled-components'
import { backgroundUser } from 'shared/icons'
import Link from 'next/link'
import { media, screenSizes } from 'utilities/helpers'
import { themeDark, themeLight } from 'styles/theme'
import NextImage from '../nextImage/NextImage'

const colors = {
  bgColor: '#0d0d0d',
}
export const HeaderWrapper = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 30px;
  align-items: center;
  padding: 10px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10000;
  background: ${({ themeMode }) => (themeMode === 'light' ? themeDark.white : themeLight.text)};
  box-shadow: ${({ themeMode }) =>
    themeMode === 'light'
      ? '0 3px 10px rgb(0 0 0 / 0.2)'
      : ' 0px -4px 21px rgb(149 149 149 / 25%)'};
  .logo-icon {
    height: 45px;
  }
  @media (max-width: 1400px) {
    grid-template-columns: 136px 50% auto;
    padding: 20px 20px;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 120px auto 40px;
    grid-gap: 30px;
    padding: 20px 10px;
  }
  ${media.md`
   grid-template-columns: 110px auto 40px;
   padding:20px 10px;
  `}
`
export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
// header search -> relative -> input and icon
export const HeaderSearch = styled.div<any>`
  position: relative;
  min-width: 366px;
  border-radius: 48px;
  padding: 14px 19px;
  display: flex;
  justify-content: space-between;
  background: #262626;
  opacity: 0.6;
  width: 100%;
  .input-search {
    width: 90%;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: ${props => props.theme.text};
    background: transparent;
    outline: none;
    border: none;
    ::placeholder {
      color: rgb(255 255 255 / 50%);
    }
  }
  .search-icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
`

export const HeaderNavigation = styled.nav`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(7, auto);
  grid-gap: 50px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, auto);
    grid-gap: 0;
  }
  a {
    color: ${props => props.theme.text};
    text-decoration: none;
    transition: all 0.2s;
    :hover {
      color: ${themeDark.brandColor};
    }
    @media (max-width: 1400px) {
      display: none;
    }
  }
`
export const HeaderUser = styled.div<any>`
  width: 45px;
  height: 45px;
  cursor: pointer;
  position: relative;
  border-radius: 100%;
  border: 2px solid ${props => props.theme.brandColor};
  background: ${props =>
    !props.src && `url(${backgroundUser.src}) no-repeat ${themeDark.textTertiary}`};
  background-position: ${props => !props.src && 'center'};
  padding: 2px;
  display: grid;
  place-items: center;
  &:hover .visible-header {
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`
export const HeaderJungleToken = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  position: relative;
  transform: translateX(-15px);
  & .btn {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    transform: translateY(4px);
  }
  .img-icon {
    // transform: translateY(5px);
  }
  .price {
    font-weight: 500;
    font-size: 16px;
  }
  .overflowBal {
    max-width: 96px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media (max-width: ${screenSizes.xl}px) {
    display: none;
  }
`
export const clickSpan = css<any>`
  span {
    background: ${props => props.theme.text};
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    transition: all linear 0.3s;
    :nth-child(1) {
      top: 0;
    }
    :nth-child(2) {
      top: 43%;
    }
    :nth-child(3) {
      bottom: 0px;
    }
  }
`
export const unClickedSpan = css<any>`
  span {
    background: ${props => props.theme.text};
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    transition: all linear 0.3s;
    :nth-child(1) {
      top: 0px;
      transform: rotate(-45deg) translate(-10px, 7px);
    }
    :nth-child(2) {
      top: 43%;
      transform: rotate(45deg) translate(0px, 2px);
    }
    :nth-child(3) {
      display: none;
    }
  }
`
export const HeaderListIcon = styled.div<any>`
  @media (max-width: 1400px) {
    height: 25px;
    cursor: pointer;
    position: relative;
    width: 30px;
    transition: all linear 0.2s;
    ${props => (props.click ? clickSpan : unClickedSpan)}
  }
`

export const HeaderHover = styled.div<any>`
  display: none;
  position: absolute;
  top: 35px;
  z-index: 10000;
  .block {
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
    border-radius: 10px;
    border: ${({ themeMode }) =>
      themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none'};
    width: 225px;
    margin-top: 20px;
    position: relative;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 5px;
    border-radius: 10px;
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light'
        ? `0px 0px 15px ${themeLight.hoverShadow}`
        : `0px 0px 15px ${themeDark.hoverShadow}`};
    /* ::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #6b6b6b;
      border-radius: 20px 20px 20px 20px;
      position: absolute;
      top: -9px;
      z-index: 1;
      left: 45%;
    } */
    .gridBlock {
      display: grid;
      grid-template-columns: 19px auto;
      grid-gap: 5px;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      transition: all linear 0.3s;
      padding: 5px 0 5px 16px;
      border-radius: 10px;
      :hover {
        background: ${themeDark.brandColor};
        color: ${themeDark.white};
      }
    }
    .image-icon {
      width: 24px;
      height: 24px;
      background: ${({ themeMode }) =>
        themeMode === 'light' ? themeLight.bgTertiary : themeDark.white};
      display: grid;
      place-items: center;
      border-radius: 100%;
    }
    .text-left {
      text-align: left;
      padding: 10px;
    }
    .toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`

export const NavTabs = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: relative;
  cursor: pointer;
  @media (max-width: 1400px) {
    &:nth-child(1) {
      display: none;
    }
    &:nth-child(2) {
      display: none;
    }
    &:nth-child(3) {
      display: none;
    }
    &:nth-child(4) {
      display: none;
    }
    &:nth-child(5) {
      display: none;
    }
  }
  @media (max-width: 1200px) {
    display: none;
  }
`

export const MenuHover = styled.div<any>`
  position: absolute;
  top: 55px;
  left: 3px;
  z-index: 10000;
  .block {
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
    border-radius: 10px;
    border: ${({ themeMode }) =>
      themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none'};
    min-height: 100px;
    width: 10rem;
    position: relative;
    display: grid;
    border-radius: 10px;
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light'
        ? `0px 0px 15px ${themeLight.hoverShadow}`
        : `0px 0px 15px ${themeDark.hoverShadow}`};
    /* ::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #6b6b6b;
      border-radius: 20px 20px 20px 20px;
      position: absolute;
      top: -9px;
      z-index: 1;
      left: 45%;
    } */
    .text-left {
      text-align: left;
      padding: 10px 30px;
      transition: all 0.2s;
      border-radius: 10px;
      :hover {
        background: ${themeDark.brandColor};
        color: ${themeDark.white};
      }
    }
  }
`

export const MenuHoverBlock = styled.div<any>`
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
  border-radius: 8px;
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none')};
  height: 100px;
  width: 10rem;
  padding: 25px;
  position: relative;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

export const ExploreHover = styled.div<any>`
  position: absolute;
  top: 55px;
  left: 3px;
  z-index: 10000;
  .block {
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
    border: ${({ themeMode }) =>
      themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none'};
    width: 225px;
    position: relative;
    border-radius: 10px;
    height: auto;
    max-height: 500px;
    overflow: auto;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light'
        ? `0px 0px 15px ${themeLight.hoverShadow}`
        : `0px 0px 15px ${themeDark.hoverShadow}`};
    /* ::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #6b6b6b;
      border-radius: 20px 20px 20px 20px;
      position: absolute;
      top: -9px;
      z-index: 1;
      left: 45%;
    } */
    .gridExploreBlock {
      display: grid;
      grid-template-columns: 26px auto;
      grid-gap: 5px;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      transition: all linear 0.3s;
      padding: 5px 0 5px 16px;
      border-radius: 10px;
      :hover {
        background: ${themeDark.brandColor};
        color: ${themeDark.white};
      }

      div {
        img {
          border-radius: 50%;
        }
      }
    }
  }
`

export const ExploreImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeDark.white};
  border-radius: 50%;
  width: 26px;
  height: 26px;
  z-index: 100;
  padding: 3px;
`

export const ExploreRightBlock = styled.div`
  text-align: left;
  text-transform: capitalize;
  padding: 10px;
  transition: all linear 0.2s;
  overflow: hidden !important;
  border-radius: 10px;
`

export const ExploreHoverBlock = styled.div<any>`
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
  border-radius: 8px;
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none')};
  min-height: 100px;
  width: 250px;
  padding: 25px;
  position: relative;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

export const TextLeft = styled.div`
  text-align: left;
  text-transform: capitalize;
  padding: 15px 20px;
  transition: all linear 0.2s;
  overflow: hidden !important;
  border-radius: 10px;
  :hover {
    background: ${themeDark.brandColor};
    color: ${themeDark.white};
    border-radius: 10px;
  }
`

export const RouteLink = styled(Link)`
  &:active {
    display: none;
  }
`

export const ResorcesHover = styled.div<any>`
  position: absolute;
  top: 55px;
  left: 3px;
  z-index: 10000;
  .block {
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
    border-radius: 10px;
    border: ${({ themeMode }) =>
      themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none'};
    /* height: 100px; */
    width: 225px;
    position: relative;
    display: grid;
    border-radius: 10px;
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light'
        ? `0px 0px 15px ${themeLight.hoverShadow}`
        : `0px 0px 15px ${themeDark.hoverShadow}`};
    ::after {
      /* content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #6b6b6b;
      border-radius: 20px 20px 20px 20px;
      position: absolute;
      top: -9px;
      z-index: 1;
      left: 45%; */
    }
    .text-left {
      padding: 20px 30px;
      text-align: left;
      :hover {
        background: ${themeDark.brandColor};
        color: ${themeDark.white};
      }
    }
  }
`

export const ResorcesHoverBlock = styled.div<any>`
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
  /* height: 100px; */
  width: 250px;
  padding: 25px;
  position: relative;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`
export const ResourcesIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`
export const ResourcesImage = styled(NextImage)`
  // :hover {
  //   background: ${themeDark.brandColor};
  //   opacity: 0.7 !important;
  //   filter: alpha(opacity=70) !important; /* For IE8 and earlier */
  //   box-shadow: 0 0 0px #000000 !important;
  // }
`

export const DropdownBody = styled.div`
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(186, 169, 255, 0.07);
  border: 1px solid rgba(186, 169, 255, 0.1);
  backdrop-filter: blur(15px);
`

export const DropdownContainer = styled.div`
  width: 30%;
  background: ${themeDark.assetDetail.background};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 10000000;
  right: 0;
  top: 100px;
  backdrop-filter: blur(15px);
  height: 100%;
`

export const DropdownHeader = styled.div`
  border-bottom: 1px solid ${themeDark.textTertiary};
  padding: 20px;
  display: flex;
  align-items: center;
  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: ${themeDark.white};
    margin: 0 5px;
  }
`

export const LoginWalletProvider = styled.div<any>`
  width: 100%;
  height: 80px;
  background: ${colors.bgColor};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin: auto auto 20px auto;
  cursor: pointer;
  position: relative;
`
export const LoginWalletProviderName = styled.h4<any>`
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.909091px;
  margin-left: 15px;
  flex: 1;
`
export const BodyWrapper = styled.div`
  padding: 20px;
`
export const LoginWalletProviderDescription = styled.p<any>`
  font-weight: 500;
  font-size: 14px;
  float: right;
  letter-spacing: 0.909091px;
  color: ${themeDark.assetDetail.cardTitle};
`
export const InfoText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.909091px;
  color: ${themeDark.white};
  margin: 20px;
  span {
    color: ${themeDark.brandColor};
    cursor: pointer;
    margin: 0 5px;
  }
`

export const MobileWrap = styled.div<any>`
  height: calc(100% - 130px);
  width: 100%;
  position: fixed;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
  overflow: auto;
  top: 82px;
  right: 0;
  z-index: 100000;
  padding: 20px 0;
  transition: 0.2s;
`
export const MobileFlex = styled.div<any>`
  padding: 20px 24px;
  cursor: pointer;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
  }
  display: flex;
  justify-content: ${({ active }) => (active ? 'start' : 'space-between')};
  align-items: center;
`
export const MobileWrapFooter = styled.div<any>`
  background: ${({ themeMode }) => (themeMode === 'light' ? themeDark.white : themeLight.text)};
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none')};
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
`
export const MobileTab = styled.div<any>`
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
  list-style: none;
  height: 100%;
  width: 100%;
  overflow: scroll;
  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    text-transform: capitalize;
    text-decoration: none;
    color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.body_bg : themeDark.white)};
  }
`
export const MobileTabs = styled.p`
  padding: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
`
export const ModifiedMobileWrap = styled.div<any>`
  z-index: 20;
  top: 78px;
  padding: 0;
  height: calc(100% - 78px);
  width: 100%;
  position: fixed;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
  overflow: auto;
  transition: 0.2s;
`
export const ModifiedMobileFlex = styled(MobileFlex)<any>`
  justify-content: start;
  background: ${({ themeMode }) => (themeMode === 'light' ? themeLight.bgTertiary : '#3a3a46')};
  p {
    margin: 0 10px;
  }
`
export const ArrowWrap = styled.div`
  padding: 0 20px;
  place-items: center;
`
export const SearchInput = styled.input<any>`
  border: none;
  padding: 10px;
  background: transparent;
  border-radius: inherit;
  width: 100%;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  outline: none;
  ::placeholder {
    font-size: 16px;
    color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  }
`

export const SearchIconWrapper = styled.div`
  height: 20px;
  width: 100%;
  display: none;
  ${media.md`
  display:flex;
  justify-content:end;
  `}
`
export const SearchWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  /* overflow-x: hidden; */
  transition: 0.2s;
  width: 100%;
  height: 100px;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none')};
  padding: 20px;
`
export const AutoCompleteDiv = styled.div<any>`
  ${media.md`
    display: none;
  `}
`

export const IconWrapper = styled.div`
  border-radius: 50px;
  background: ${themeDark.white};
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

import { useWeb3React } from '@web3-react/core'
import { useAppSelector } from 'hooks/useAppSelector'
import {
  setAccountLoading,
  setChainType,
  setIsLoggedIn,
  setIsUserSwitchedWallet,
  setUserFromLoginPage,
  setUserFromSidebar,
} from 'logic/redux/slices/userSlice'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'

import {
  fortmatic,
  injected,
  walletConnect,
  walletLink,
  portis,
  torus,
  authereum,
  ETHEREUM_TESTNET_CHAIN_ID,
  POLYGON_TESTNET_CHAIN_ID,
} from '../wallet/connectors'
import { I_LoginOptions, useLoginMutation, useUserLogoutQuery } from 'logic/reactQuery/userService'
import toast from 'react-hot-toast'

const ReConnect = () => {
  const { mutateAsync: mutateLogoutUser, isLoading: userLogoutLoading } = useUserLogoutQuery()
  const { mutateAsync, isLoading, data: loginInfo }: any = useLoginMutation()
  const dispatch = useDispatch()
  const { activate, account, active, deactivate, error, chainId, library } = useWeb3React()
  const router = useRouter()
  const { isLoggedIn, isAccountLoading, userFromLoginPage, userFromSidebar, isUserSwitchedWallet } =
    useAppSelector(state => state.user)

  const providers: any = {
    fortmatic,
    injected,
    walletConnect,
    walletLink,
    portis,
    torus,
    authereum,
  }
  let provider: any
  let redirect: any
  let accessToken: any
  if (typeof window !== 'undefined') {
    provider = localStorage.getItem('provider')
    redirect = window.location.pathname
    accessToken = window.localStorage.getItem('accessToken')

  }



  useEffect(() => {
    if (typeof window !== 'undefined' && accessToken) {
      const expirationDate: any = window.localStorage.getItem('tokenExpiry')
      const isExpired = dayjs.unix(expirationDate).diff(dayjs()) < 1
      if (isExpired) {
        let authToken: any = JSON.parse(accessToken)
        const isExistingUser = window.localStorage.getItem('userInfo')
        const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
        const userAuthInfoUpdated = userAuthInfo.filter(
          (user: any) => user.accessToken !== authToken,
        )
        if (userAuthInfoUpdated) {
          const newUserInfo = [...userAuthInfoUpdated]
          typeof window !== 'undefined' &&
            window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
        }
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('tokenExpiry')
        window.localStorage.removeItem('provider')

        deactivate()
        dispatch(setUserFromLoginPage(false))
        dispatch(setAccountLoading(false))
        dispatch(setUserFromSidebar(false))
        dispatch(setIsLoggedIn(false))
        dispatch(setChainType(''))
        router.replace('/login')
      }
    }
  }, [accessToken])



  const connectWallet: any = (connector: string) => {
    dispatch(setAccountLoading(true))
    activate(providers[connector], () => {
      dispatch(setAccountLoading(false))
      typeof window !== 'undefined' && window.localStorage.removeItem('provider')
      router.push({
        pathname: '/login',
        query: { redirect },
      })
    })
  }

  if (
    provider &&
    !account &&
    !active &&
    isAccountLoading &&
    !userFromLoginPage &&
    !userFromSidebar
  ) {
    connectWallet(provider)
  }
  const userSignatureAuthorization = async (userAddress: string) => {
    try {
      const signatureMessage =
        'action= signIn&' +
        'address' +
        userAddress +
        'signUp' +
        '&timestamp=' +
        new Date()?.toISOString()

      const signatureHash = await library.eth.personal.sign(signatureMessage, userAddress)
      const data: I_LoginOptions = {
        walletAddress: userAddress,
        signature: signatureHash,
        signature_message: signatureMessage,
      }
      const response = await mutateAsync(data)
      if (response?.message?.access_token) {
        dispatch(setIsLoggedIn(true))
        if (chainId) dispatch(setChainType(chainId))

        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'accessToken',
            JSON.stringify(response?.message?.access_token),
          )
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'tokenExpiry',
            JSON.stringify(response?.message?.tokenExpirationDate),
          )

        const userInfo = [
          {
            walletAddress: userAddress,
            accessToken: response?.message?.access_token,
            tokenExpiry: response?.message?.tokenExpirationDate,
          },
        ]

        const isExistingUser =
          typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
        const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null

        if (userAuthInfo !== undefined && userAuthInfo !== null && userAuthInfo?.length > 0) {
          const userAuthInfoUpdated = userAuthInfo.filter(
            (user: any) => user.walletAddress !== userAddress,
          )
          if (userAuthInfoUpdated) {
            const newUserInfo = [...userAuthInfoUpdated, ...userInfo]
            typeof window !== 'undefined' &&
              window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
          }
        } else {
          typeof window !== 'undefined' &&
            window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
        }

        // @ts-ignore window.analytics undefined below
        window.analytics.track('User Authorized', {
          data,
        })
      } else {
        // @ts-ignore window.analytics undefined below
        window.analytics.track('User UnAuthorized', {
          data,
        })
      }
    } catch (error: any) {
      console.log(error?.message)
      toast(error?.message)
      // @ts-ignore window.analytics undefined below
      window.analytics.track('User UnAuthorized', {
        account,
      })
      disconnectWallet()
    }
  }
  const disconnectWallet: any = async (e: any) => {
    try {
      const response = await mutateLogoutUser()
      if (response?.success) {
        const isExistingUser =
          typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
        const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
        const userAuthInfoUpdated = userAuthInfo.filter(
          (user: any) => user.walletAddress !== account,
        )
        if (userAuthInfoUpdated) {
          const newUserInfo = [...userAuthInfoUpdated]
          typeof window !== 'undefined' &&
            window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
        }
        if (typeof window !== 'undefined') {
          window.localStorage.removeItem('provider')
          window.localStorage.removeItem('accessToken')
          window.localStorage.removeItem('tokenExpiry')
        }
        deactivate()
        dispatch(setUserFromLoginPage(false))
        dispatch(setAccountLoading(false))
        dispatch(setUserFromSidebar(false))
        dispatch(setIsLoggedIn(false))
        dispatch(setChainType(''))
        router.replace('/login')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const checkUserStatus = (address: string): boolean => {
    const isExistingUser = typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
    const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
    if (userAuthInfo !== undefined && userAuthInfo !== null && userAuthInfo?.length > 0) {
      const userAuthInfoUpdated: any = userAuthInfo.find(
        (user: any) => user?.walletAddress?.toLowerCase() === address?.toLowerCase(),
      )
      if (userAuthInfoUpdated !== undefined && userAuthInfoUpdated !== null) {
        // const userToken: any = jwt_decode(userAuthInfoUpdated?.accessToken)

        // const isExpired = dayjs.unix(userToken?.exp).diff(dayjs()) < 1
        const expirationDate = userAuthInfoUpdated?.tokenExpiry
        // let isExpired = (new Date().getTime()) < expirationDate
        const isExpired = dayjs.unix(expirationDate).diff(dayjs()) < 1

        if (!isExpired) {
          typeof window !== 'undefined' &&
            window.localStorage.setItem(
              'accessToken',
              JSON.stringify(userAuthInfoUpdated?.accessToken),
            )

          return true
        } else {
          disconnectWallet()
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    if (account && !isLoggedIn && !userFromLoginPage && !userFromSidebar) {
      dispatch(setAccountLoading(false))
      const userHasValidToken = checkUserStatus(account)
      if (userHasValidToken) {
        dispatch(setIsLoggedIn(true))
        if (chainId) dispatch(setChainType(chainId))
        // @ts-ignore window.analytics undefined below
        window.analytics.track('User Authorized', {
          account,
        })
        if (router.query.redirect) {
          // @ts-ignore
          router.push(router.query.redirect || router.asPath)
        }
      }
    }
  }, [account, userFromLoginPage, userFromSidebar, isLoggedIn, isAccountLoading])

  return null
}

export default ReConnect

interface AddChainParameter {
  chainId: string // A 0x-prefixed hexadecimal string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string // 2-6 characters long
    decimals: 18
  }
  rpcUrls: string[]
  blockExplorerUrls?: string[]
  iconUrls?: string[] // Currently ignored.
}

export const networkParams: { [key: string]: AddChainParameter } = {
  '0x13881': {
    chainId: '0x13881',
    rpcUrls: [process.env.POLYGON_RPC_NODE_URL as string],
    chainName: 'Mumbai',
    nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
    blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    // iconUrls: ['https://raw.githubusercontent.com/'],
  },
}

export const networkName: { [key: string | number]: string } = {
  1: 'Ethereum',
  // 3: 'Ropsten',
  // 4: 'Rinkeby',
   5: 'Goerli',
  137: 'Polygon',
  80001: 'Mumbai',
}
